<template lang="">
  <div class="edit-div">
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert v-if="alert" class="mt-4" type="error" transition="scale-transition">Error changing password</v-alert>
    <div>
      <v-expansion-panels rounded id="change-password-expasion-panels">
        <v-expansion-panel id="change-password-expasion-panel">
          <v-expansion-panel-header id="change-password-expasion-header" class="title-password">
            Change your password
          </v-expansion-panel-header>
          <v-expansion-panel-content id="change-password-expasion-content">
            <v-row v-if="alertSuccess">
              <v-col>
                <v-alert class="mt-4" type="success">successfully changed</v-alert>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <v-text-field
                  id="change-password-oldpass-field"
                  v-model="oldPassword"
                  label="Old password*"
                  :type="oldPasswordVisible ? 'text' : 'password'"
                  class="form-input"
                  placeholder="Password"
                  required
                  :append-icon="oldPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="visiblePassword('old')"
                  underlined>
                </v-text-field>
                <v-text-field
                  id="change-password-newpass-field"
                  v-model="newPassword"
                  label="New password*"
                  class="form-input"
                  :rules="[passwordRules]"
                  placeholder="Password"
                  underlined
                  :type="newPasswordVisible ? 'text' : 'password'"
                  :append-icon="newPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="visiblePassword('new')"
                  required>
                </v-text-field>
                <v-text-field
                  id="change-password-repeatpass-field"
                  v-model="repeatedPassword"
                  label="Repeat new password*"
                  class="form-input"
                  placeholder="Password"
                  underlined
                  :type="repeatedPasswordVisible ? 'text' : 'password'"
                  :append-icon="repeatedPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="visiblePassword('rep')"
                  required>
                </v-text-field>
              </v-col>
              <v-col>
                <div class="change-font">
                  <v-card-text class="password-text">
                    Password must contain:
                  </v-card-text>
                  <v-list-item>
                    <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon>{{ lengthRule }}
                  </v-list-item><br>
                  <v-list-item>
                    <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
                  </v-list-item><br>
                  <v-list-item>
                    <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
                  </v-list-item><br>
                  <v-list-item>
                    <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
                  </v-list-item><br>
                  <v-list-item>
                    <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
                  </v-list-item><br>
                </div>
                <div class="change-font text-right">
                  <v-btn
                    id="change-password-btn"
                    class="ml-5 mt-5"
                    @click="savePassword"
                    :disabled="formValid"
                    outlined
                    rounded>
                    Change my password
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { accountServices } from '@/services/accountServices';
import { PasswordChecker } from '@/utils/passwordUtilities';

export default {
  name: 'ChangePassword',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      PasswordChecker,
      newPassword: '',
      newPasswordVisible: false,
      repeatedPassword: '',
      repeatedPasswordVisible: false,
      oldPassword: '',
      oldPasswordVisible: false,
      alert: false,
      alertSuccess: false,
      loading: false,
      lengthRule: 'At least 8 characters.',
      upperRule: 'At least 1 upper case letter (A..Z)',
      lowerRule: 'At least 1 lower case letter (a..z)',
      numberRule: 'At least 1 number (0...9)',
      characterRule: 'At least 1 special character (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  computed: {
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong(this.newPassword);
      return !((validPassword)
      /* && (this.oldPassword !== this.newPassword) */
      && (this.newPassword === this.repeatedPassword)
      && (this.oldPassword !== '' && this.newPassword !== ''
      && this.repeatedPassword !== '')
      && this.colorLenRules
      && this.colorUpperRules
      && this.colorLowerRules
      && this.colorNumRules
      && this.colorCharRules);
    },
  },
  methods: {
    visiblePassword(key) {
      if (key === 'old') this.oldPasswordVisible = !this.oldPasswordVisible;
      else if (key === 'new') this.newPasswordVisible = !this.newPasswordVisible;
      else if (key === 'rep') this.repeatedPasswordVisible = !this.repeatedPasswordVisible;
    },
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    savePassword() {
      this.loading = true;
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password2: this.repeatedPassword,
      };
      accountServices.changePassword(data)
        .then(() => {
          this.loading = false;
          this.alertSuccess = true;
          setTimeout(() => {
            this.alertSuccess = false;
            this.oldPassword = '';
            this.newPassword = '';
            this.repeatedPassword = '';
            this.$router.push('/home');
          }, 4000);
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          setTimeout(() => {
            this.alert = false;
          }, 4000);
        });
    },
  },
};
</script>
<style scoped>
  .change-font {
    margin-top: 40px;
    margin-left: 40px;
    font-size: 16px;
    line-height: 0;
  }
  .title-password {
    font-size: 20px !important;
  }
  .password-text {
    font-size: 20px !important;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .form-input {
    margin: 24px;
  }
  .v-expansion-panel,
  .v-expansion-panels {
    border-radius: 8px !important;
  }
</style>
