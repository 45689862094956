<template lang="">
  <div>
    <v-alert v-if="alert" :type="typeAlert" transition="scale-transition">{{error}}</v-alert>
    <v-alert v-if="!cookieManager.hasReadAllAppDocuments()" type="warning" class="alert-warning">
      First you must open and mark as read all the documents bellow</v-alert>
    <v-expansion-panels rounded id="documentation-profile-expasion-panels" v-model="panels">
      <v-expansion-panel id="documentation-profile-expasion-panel">
        <v-expansion-panel-header id="documentation-profile-expasion-header" class="title-password">
          Documentation and instructions
        </v-expansion-panel-header>
        <v-expansion-panel-content id="documentation-profile-expasion-content">
          <v-row>
            <v-col cols="12">
              <div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details></v-text-field>
              </div>
              <div>
                <v-data-table :headers="headers" :items="documentsList" :search="search" @click:row="tableRowClick"
                :loading="loading" loading-text="Loading documents...">
                  <template v-slot:item.name="{ item }">
                    <span class="h-cursor tfe-primary-color">
                      <v-icon color="primary">mdi-eye-outline</v-icon> {{ item.name }}</span>
                  </template>
                  <template v-slot:item.is_open="{ item }">
                    <boolean-label v-bind:active="item.is_open"/>
                  </template>
                  <template v-slot:item.is_read="{ item }">
                    <boolean-label v-bind:active="item.is_read"/>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12 text-right">
              <v-btn outlined rounded @click="downloadCertificate()" color="primary"
              :disabled="!cookieManager.hasReadAllAppDocuments()">Download certificate</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="showDocumentDialog" max-width="1200" :retain-focus="false" class="mt-2">
      <v-card class="mt-0">
        <v-card-title><span class="text-h5">{{itemSelected.name}}</span></v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <embed
                  id="documentDialog"
                  v-bind:src="fileView"
                  :type="embedType"
                  width=100%
                  height="100%"
                  :key="fileView">
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="showDocumentDialog=false" color="error">Close</v-btn>
          <v-btn v-if="!itemSelected.is_read" outlined rounded @click="markAsRead()" color="primary">
            Mark as read</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { accountServices } from '@/services/accountServices';
import { roleManager } from '@/store/roleManager';
import cookieManager from '@/store/cookieManager';
import BooleanLabel from '@/components/generic/BooleanLabel.vue';

export default {
  name: 'DocumentationProfile',
  components: {
    BooleanLabel,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      roleManager,
      cookieManager,
      search: '',
      error: '',
      typeAlert: 'success',
      alert: false,
      loading: false,
      documentsList: [],
      showDocumentDialog: false,
      fileView: '',
      panels: null,
      itemSelected: {},
    };
  },
  mounted() {
    this.getDocumentation();
    this.panels = this.open ? 0 : null;
  },
  computed: {
    headers() {
      const items = [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'documentation_type' },
        { text: 'Open status', value: 'is_open' },
        { text: 'Read status', value: 'is_read' },
      ];
      if (roleManager.isAdmin()) {
        items.splice(2, 0, { text: 'Role', value: 'role' });
      }
      return items;
    },
    embedType() {
      if (this.itemSelected.documentation_type === 'image') return 'image/png';
      if (this.itemSelected.documentation_type === 'video') return 'video/mp4';
      return 'application/pdf';
    },
  },
  methods: {
    checkUserData() {
      accountServices.checkUserData()
        .then((response) => {
          cookieManager.set('has_read_all_app_documents', response.data.has_read_all_app_documents);
          if (response.data.has_read_all_app_documents) {
            this.$router.push('/home');
          }
        })
        .catch(() => {});
    },
    getDocumentation() {
      this.loading = true;
      accountServices.getDocumentationProfile()
        .then((response) => {
          this.loading = false;
          this.documentsList = response.data;
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          this.error = 'Error loading documents';
          this.typeAlert = 'error';
          setTimeout(() => {
            this.alert = false;
          }, 5000);
        });
    },
    tableRowClick(item) {
      this.fileView = '';
      this.itemSelected = item;
      this.itemSelected.is_open = true;
      this.getDocumentationProfileUrl();
    },
    getDocumentationProfileUrl() {
      const usrid = cookieManager.get('user_id', Number);
      const url = process.env.VUE_APP_BASE_URL;
      this.fileView = `${url}/api/users/documentation/${this.itemSelected.id}/check-file/?usrid=${usrid}`;
      this.showDocumentDialog = true;
      // this.loading = true;
      // accountServices.getDocumentationProfileUrl(documentId)
      //   .then((response) => {
      //     this.loading = false;
      //     window.open(response.data.url, '_blank');
      //   })
      //   .catch(() => {
      //     this.alert = true;
      //     this.loading = false;
      //     this.error = 'Error loading document content';
      //     this.typeAlert = 'error';
      //     setTimeout(() => {
      //       this.alert = false;
      //     }, 3000);
      //   });
    },
    markAsRead() {
      this.loading = true;
      accountServices.markDocumentationProfileRead(this.itemSelected.id)
        .then(() => {
          this.loading = false;
          this.itemSelected.is_read = true;
          this.showDocumentDialog = false;
          this.itemSelected = {};
          this.checkUserData();
        })
        .catch((error) => {
          this.alert = true;
          this.loading = false;
          this.error = error.response.data.detail;
          this.typeAlert = 'error';
          setTimeout(() => {
            this.alert = false;
          }, 7000);
        });
    },
    downloadCertificate() {
      accountServices.downloadCertificate()
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Certificate.pdf';
          link.click();
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
  .v-alert {
    z-index: 999;
  }
  #documentDialog {
    height: 72vh;
  }
  .title-password {
    font-size: 20px !important;
  }
  .v-expansion-panel,
  .v-expansion-panels {
    border-radius: 8px !important;
  }
  .alert-warning {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    z-index: 0;
  }
</style>
