<template>
  <div>
    <app-header />
    <nav-bar />
    <v-container>
      <v-alert v-if="alert" class="mt-4" type="success">Successfully changed</v-alert>
      <v-card class="profile-style">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="fullName" readonly underlined class="register-name-style no-border">
                  <template slot="append-outer">
                    <v-icon v-if="updateUsername" color="primary" @click="saveUsername()">mdi-content-save</v-icon>
                  </template>
                  <template slot="append">
                    <v-icon @click="updateName()">mdi-pencil</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-avatar color="secondary">
                  <span class="white--text text-h5">{{ initials }}</span>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="updateUsername">
                <v-text-field v-model="register_name" label="Name" underlined>
                </v-text-field>
                <v-text-field v-model="register_last_name" label="Last name" underlined>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field underlined readonly v-model="role" label="Role" class="no-border">{{ role }}
                </v-text-field>
                <v-text-field underlined readonly v-model="email" class="no-border" label="email">{{ email }}
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <change-password class="mt-8"/>
      <Documentation-profile class="mt-8" :open="docStartOpen" :key="docStartOpen"/>
    </v-container>
  </div>
</template>
<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { accountServices } from '@/services/accountServices';
import ChangePassword from '@/components/ChangePassword.vue';
import DocumentationProfile from '@/components/DocumentationProfile.vue';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'UserProfile',
  components: {
    AppHeader,
    NavBar,
    ChangePassword,
    DocumentationProfile,
  },
  data() {
    return {
      register_name: ' ',
      register_last_name: '',
      role: '',
      email: '',
      updateUsername: false,
      alert: false,
      docStartOpen: false,
    };
  },
  mounted() {
    this.checkUserData();
  },
  computed: {
    fullName() {
      return `${this.register_name} ${this.register_last_name}`;
    },
    initials() {
      return `${this.register_name.charAt(0)}${this.register_last_name.charAt(0)}`.toUpperCase();
    },
  },
  methods: {
    checkUserData() {
      accountServices
        .checkUserData()
        .then((response) => {
          this.id = response.data.id;
          this.register_name = response.data.first_name;
          this.register_last_name = response.data.last_name;
          this.role = response.data.role;
          this.email = response.data.email;
          this.docStartOpen = !response.data.has_read_all_app_documents;
          cookieManager.set('has_read_all_app_documents', response.data.has_read_all_app_documents);
        })
        .catch(() => {});
    },
    updateName() {
      this.updateUsername = !this.updateUsername;
    },
    saveUsername() {
      this.data = {
        first_name: this.register_name,
        last_name: this.register_last_name,
      };
      accountServices.updateUsername(this.id, this.data)
        .then((response) => {
          this.alert = true;
          this.register_name = response.data.first_name;
          this.register_last_name = response.data.last_name;
          this.updateUsername = false;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
    },
  },
};
</script>
<style scoped>
  .register-name-style {
    font-size: 32px !important;
    text-decoration-color: white !important;
  }
</style>
